import '@reach/tabs/styles.css'

import * as S from './styles'
import * as Yup from 'yup'

import {
  CartHelper,
  DealerType,
  ProductHelper,
  useCartContext,
  useChannel,
  formatDateToUTC,
  isNonEmptyArray,
} from '@monorepo/infra'
import { CompsPayload, useComps } from './useComps'
import {
  DeliveryMethod,
  Product,
  ProductType,
  ProductVariant,
} from '@monorepo/interfaces'
import { Input, Spinner } from '@monorepo/components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { setNestedObjectValues, useFormik } from 'formik'

import { SectionCartList } from '..'
import Slider from 'react-slick'
import { TabPanel } from '@reach/tabs'
import { useBoxFields } from './useBoxFields'

const addToCartTextStr = 'ADD TO CART'
const requestConsultationTextStr = 'REQUEST CONSULTATION'

const consulationCheckProductTypes = [
  ProductType.CREATIVE,
  ProductType.CREATIVE_CUSTOM,
  ProductType.EVENT,
]

const consulattionCheckDeliveryMethods = [
  DeliveryMethod.CUSTOM_QUOTE,
  DeliveryMethod.PLACE_WITH_AGENCY,
]

const tryGetSelectedProductPrice = (
  selectedVariantIndex: number,
  selectedDeliveryMethodIndex: number,
  selectedPrintAndShipIndex: number,
  product?: Product
): number | null => {
  if (!product) {
    return null
  }

  const price = CartHelper.getProductPrice({
    product,
    selectedVariantIndex: selectedVariantIndex,
    selectedDeliveryMethodIndex: selectedDeliveryMethodIndex,
    selectedPrintAndShipIndex: selectedPrintAndShipIndex,
    quantity: 1,
  })

  return isNaN(price) || !isFinite(price) ? null : price
}

type ProductDetailProps = {
  product?: Product
  variant?: ProductVariant
  isMedia?: boolean
  onChangeVariant: (index: number) => void
}

const ProductDetail: React.FC<ProductDetailProps> = ({
  product,
  variant,
  isMedia,
  onChangeVariant,
}) => {
  const hasPrintAndShip = product?.deliveryMethods?.includes(
    DeliveryMethod.PRINT
  )
  const hasDownload = product?.deliveryMethods?.includes(
    DeliveryMethod.DOWNLOAD
  )
  const hasCustom =
    product?.deliveryMethods?.includes(DeliveryMethod.CUSTOM_QUOTE) ||
    product?.deliveryMethods?.includes(DeliveryMethod.PLACE_WITH_AGENCY)
  const hasOptions =
    (product?.type === ProductType.CREATIVE_CUSTOM ||
      product?.type === ProductType.MICROSITE) &&
    product?.variants &&
    product.variants.length > 1

  const [selectedVariant, setSelectedVariant] = useState(0)
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    (hasDownload
      ? DeliveryMethod.DOWNLOAD
      : hasCustom
      ? DeliveryMethod.CUSTOM_QUOTE
      : product && product?.deliveryMethods?.[0]) ?? DeliveryMethod.CUSTOM_QUOTE
  )
  const [selectedPrintAndShipOption, setSelectedPrintAndShipOption] =
    useState(0)
  const [notes, setNotes] = useState('')

  const channel = useChannel()
  const [selectedMasterDealerIndex, setSelectedMasterDealerIndex] = useState(0)
  useEffect(() => {
    let newIndex = 0
    if (
      channel.associatedMasterDealerIds &&
      channel.associatedMasterDealerIds.length > 0
    ) {
      newIndex = channel.associatedMasterDealerIds.findIndex(
        (amd) => amd.masterDealerId === channel.selectedMasterDealerId
      )
    }
    setSelectedMasterDealerIndex(newIndex > 0 ? newIndex : 0)
  }, [channel.associatedMasterDealerIds, channel.selectedMasterDealerId])

  const inDesignFile = product?.variants?.[selectedVariant]
    ?.inDesignFile as string

  const {
    boxfields,
    shouldShowSomeBoxfield,
    fetchStatus: fetchStatusBFields,
    fetchStatuses: fetchStatusesBFields,
  } = useBoxFields({
    uri: inDesignFile,
  })

  const [currentCompsPayload, setCurrentCompsPayload] =
    useState<Partial<CompsPayload> | null>(null)

  const isSameCompsPayload = useCallback(
    (newPayload: Partial<CompsPayload>): boolean => {
      if (!currentCompsPayload) return false

      const isLengthChanged =
        Object.keys(newPayload).length !==
        Object.keys(currentCompsPayload).length

      if (isLengthChanged) return false

      const isSame = Object.entries(newPayload).every(
        ([key, value]) =>
          value === currentCompsPayload[key as keyof CompsPayload]
      )

      return isSame
    },
    [currentCompsPayload]
  )

  const {
    dealerImage: uploadedDealerImage,
    previewImages,
    fetchStatus: fetchStatusComps,
    fetchStatuses: fetchStatusesComps,
    getComps,
    uploadDealerImage,
  } = useComps()

  const [showPreviewModal, setShowPreviewModal] = useState(false)

  const [showCustomization, setShowCustomization] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [customizationValues, setCustomizationValues]: any = useState(null)
  const [dealerImage, setDealerImage] = useState<File>()
  const [showDrawer, setShowDrawer] = useState(false)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const { addProduct } = useCartContext()

  const toggleModal = useCallback(() => {
    setShowPreviewModal((show) => !show)
  }, [])

  const addToCartText = useMemo((): string => {
    if (
      !product?.type ||
      !consulationCheckProductTypes.includes(product.type) ||
      !consulattionCheckDeliveryMethods.includes(selectedDeliveryMethod)
    ) {
      return addToCartTextStr
    }

    const price = tryGetSelectedProductPrice(
      selectedVariant,
      selectedDeliveryMethod,
      selectedPrintAndShipOption,
      product
    )

    return price === 0 ? requestConsultationTextStr : addToCartTextStr
  }, [
    selectedVariant,
    selectedDeliveryMethod,
    selectedPrintAndShipOption,
    product,
    product?.deliveryMethods,
  ])

  const handleSelectVariant = (index: number) => {
    setSelectedVariant(index)
    onChangeVariant(index)
  }

  const {
    setFieldValue,
    validateForm,
    setTouched,
    submitForm,
    errors,
    touched,
    setFieldTouched,
    values,
  } = useFormik({
    initialValues: {
      dealerName: customizationValues?.dealerName || '',
      dealerAddress: customizationValues?.dealerAddress || '',
      dealerAddress2: customizationValues?.dealerAddress2 || '',
      phoneNumber: customizationValues?.phoneNumber || '',
      dealerImage: customizationValues?.dealerImage || undefined,
    },
    validationSchema: Yup.object().shape({
      dealerName: Yup.string().optional(),
      dealerAddress: Yup.string().optional(),
      dealerAddress2: Yup.string().optional(),
      phoneNumber: Yup.string().optional(),
      dealerImage: Yup.mixed().optional(),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const isAllValuesEmpty = Object.values(values).every((value) =>
        typeof value === 'string' ? !value.trim() : !value
      )

      if (isAllValuesEmpty) {
        setCustomizationValues(null)
        setShowCustomization(false)
        return
      }

      if (values.dealerImage) {
        uploadDealerImage(values.dealerImage)
      }
      delete values.dealerImage
      setCustomizationValues(values)
      setShowCustomization(false)
    },
  })

  useEffect(() => {
    const shouldScrollToTop = product || showCustomization

    shouldScrollToTop && window.scrollTo(0, 0)
  }, [product, showCustomization])

  useEffect(() => {
    if (isMedia) {
      setSelectedDeliveryMethod(DeliveryMethod.CUSTOM_QUOTE)
    }
  }, [isMedia])

  if (!product || Array.isArray(product) || !variant) return <div>Loading</div>

  const dealerName =
    customizationValues !== null ? customizationValues.dealerName : ''
  const dealerAddress =
    customizationValues !== null ? customizationValues.dealerAddress : ''
  const dealerAddress2 =
    customizationValues !== null ? customizationValues.dealerAddress2 : ''
  const dealerPhone =
    customizationValues !== null ? customizationValues.phoneNumber : ''
  const cartProduct = {
    product,
    selectedVariantIndex: selectedVariant,
    selectedDeliveryMethodIndex: selectedDeliveryMethod,
    selectedPrintAndShipIndex: selectedPrintAndShipOption,
    dealerName,
    dealerAddress,
    dealerAddress2,
    dealerPhone,
    dealerImage: uploadedDealerImage,
    notes,
    quantity: 1,
  }

  return (
    <S.Wrap>
      <S.Header>
        <div>
          <S.ProductTitle>{product.title}</S.ProductTitle>
          <S.VariantPrice>
            {formatter.format(
              CartHelper.getProductPrice({
                product,
                selectedVariantIndex: selectedVariant,
                selectedDeliveryMethodIndex: selectedDeliveryMethod,
                selectedPrintAndShipIndex: selectedPrintAndShipOption,
                quantity: 1,
              })
            )}
          </S.VariantPrice>
          <S.ProductDescription>{product.description}</S.ProductDescription>
        </div>
        <span>{formatDateToUTC(product.expirationDate as Date)}</span>
      </S.Header>
      {isMedia ? (
        <S.PanelCustom style={{ marginBottom: '40px' }}>
          <S.Card
            isActive={selectedDeliveryMethod === DeliveryMethod.CUSTOM_QUOTE}
          >
            <p>Custom order</p>
            <p>
              {formatter.format(
                CartHelper.getProductPrice({
                  product,
                  selectedDeliveryMethodIndex: selectedDeliveryMethod,
                  selectedPrintAndShipIndex: selectedPrintAndShipOption,
                  selectedVariantIndex: selectedVariant,
                  quantity: 1,
                })
              )}
            </p>
          </S.Card>
          <S.TextArea
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value)
            }}
            placeholder="Include details about your custom request"
          />
        </S.PanelCustom>
      ) : (
        <>
          {hasOptions && (
            <S.Sizes>
              <S.PanelTitle>Choose Option</S.PanelTitle>
              <S.Panel>
                {product.variants.map((item, index) => {
                  return (
                    <S.Card
                      key={`variants-${index}`}
                      onClick={() => handleSelectVariant(index)}
                      isActive={selectedVariant === index}
                    >
                      <S.SizeName>{item.title}</S.SizeName>
                    </S.Card>
                  )
                })}
              </S.Panel>
            </S.Sizes>
          )}
          <S.OrderTab
            defaultIndex={
              hasDownload
                ? DeliveryMethod.DOWNLOAD
                : hasCustom
                ? DeliveryMethod.CUSTOM_QUOTE
                : hasPrintAndShip
                ? DeliveryMethod.PRINT
                : undefined
            }
            onChange={(index) => setSelectedDeliveryMethod(index)}
          >
            <S.TabTitleList>
              {hasPrintAndShip && (
                <S.TabTitle
                  index={DeliveryMethod.PRINT}
                  tabIndex={DeliveryMethod.PRINT}
                >
                  Print & Ship
                </S.TabTitle>
              )}
              {hasDownload && (
                <S.TabTitle
                  index={DeliveryMethod.DOWNLOAD}
                  tabIndex={DeliveryMethod.DOWNLOAD}
                >
                  Download
                </S.TabTitle>
              )}
              {hasCustom && (
                <S.TabTitle
                  index={DeliveryMethod.CUSTOM_QUOTE}
                  tabIndex={DeliveryMethod.CUSTOM_QUOTE}
                >
                  {hasOptions ? 'Details' : 'Custom order'}
                </S.TabTitle>
              )}
            </S.TabTitleList>
            <S.Panels>
              {hasPrintAndShip && (
                <TabPanel
                  index={DeliveryMethod.PRINT}
                  tabIndex={DeliveryMethod.PRINT}
                >
                  <S.Panel>
                    {variant.printAndShipOptions.map((item, index: number) => {
                      return (
                        <S.Card
                          key={`count-and-price-${index}`}
                          onClick={() => setSelectedPrintAndShipOption(index)}
                          isActive={selectedPrintAndShipOption === index}
                        >
                          <p>{`${item.pieceCount} Count`}</p>
                          <p>{`$ ${item.price}`}</p>
                        </S.Card>
                      )
                    })}
                  </S.Panel>
                </TabPanel>
              )}
              {hasDownload && (
                <TabPanel
                  index={DeliveryMethod.DOWNLOAD}
                  tabIndex={DeliveryMethod.DOWNLOAD}
                >
                  <S.Panel>
                    <S.Card
                      isActive={
                        selectedDeliveryMethod === DeliveryMethod.DOWNLOAD
                      }
                      onClick={() =>
                        setSelectedDeliveryMethod(DeliveryMethod.DOWNLOAD)
                      }
                    >
                      <p>Download</p>
                      <p>{formatter.format(0.0)}</p>
                    </S.Card>
                  </S.Panel>
                </TabPanel>
              )}
              {hasCustom && (
                <TabPanel
                  index={DeliveryMethod.CUSTOM_QUOTE}
                  tabIndex={DeliveryMethod.CUSTOM_QUOTE}
                >
                  <S.PanelCustom>
                    <S.Card
                      isActive={
                        selectedDeliveryMethod === DeliveryMethod.CUSTOM_QUOTE
                      }
                      onClick={() =>
                        setSelectedDeliveryMethod(DeliveryMethod.CUSTOM_QUOTE)
                      }
                    >
                      <p>Custom Order</p>
                      <p>
                        {formatter.format(
                          CartHelper.getProductPrice({
                            product,
                            selectedDeliveryMethodIndex: selectedDeliveryMethod,
                            selectedPrintAndShipIndex:
                              selectedPrintAndShipOption,
                            selectedVariantIndex: selectedVariant,
                            quantity: 1,
                          })
                        )}
                      </p>
                    </S.Card>
                    <S.TextArea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder="Include details about your custom request"
                    />
                  </S.PanelCustom>
                </TabPanel>
              )}
            </S.Panels>
          </S.OrderTab>
        </>
      )}
      {product.type === ProductType.CREATIVE && (
        <S.Sizes>
          <S.PanelTitle>Choose Size</S.PanelTitle>
          <S.PanelSize>
            {product.variants.map((item, index) => {
              let title = ProductHelper.getVariantTitle(item)
              const titleIdx = title.lastIndexOf('"') + 1
              title =
                title.slice(0, titleIdx) + '<br />' + title.slice(titleIdx)
              return (
                <S.CardSize
                  key={`variants-${index}`}
                  onClick={() => handleSelectVariant(index)}
                  isActive={selectedVariant === index}
                >
                  <S.SizeName
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  ></S.SizeName>
                </S.CardSize>
              )
            })}
          </S.PanelSize>
        </S.Sizes>
      )}
      {product.type === ProductType.CREATIVE &&
        (shouldShowSomeBoxfield ||
          fetchStatusesBFields.INITIAL !== fetchStatusBFields) && (
          <S.Customization>
            <S.PanelTitle>Make it yours</S.PanelTitle>
            <S.CustomizationCard hasCustomization={customizationValues}>
              {fetchStatusesBFields.FETCHING === fetchStatusBFields ? (
                <S.SpinnerContainer>
                  <Spinner spinnerSize={8} />
                </S.SpinnerContainer>
              ) : fetchStatusesBFields.ERROR === fetchStatusBFields ? (
                'An error occurred retrieving box fields'
              ) : customizationValues ? (
                <S.CustomizationColumn>
                  {customizationValues.dealerName && (
                    <S.CustomizationCardTitle>
                      {customizationValues.dealerName}
                    </S.CustomizationCardTitle>
                  )}
                  {customizationValues.dealerAddress && (
                    <p>{customizationValues.dealerAddress}</p>
                  )}
                  {customizationValues.dealerAddress2 && (
                    <p>{customizationValues.dealerAddress2}</p>
                  )}
                  {customizationValues.phoneNumber && (
                    <p>{customizationValues.phoneNumber}</p>
                  )}
                  {dealerImage && <p>{dealerImage.name}</p>}
                </S.CustomizationColumn>
              ) : (
                <S.CustomizationCardTitle>
                  Customize this design with your contact information
                </S.CustomizationCardTitle>
              )}
              {customizationValues &&
                fetchStatusesBFields.FETCHING !== fetchStatusBFields && (
                  <S.CustomizationButtonsContainer>
                    <S.CustomizationAction
                      onClick={() => setShowCustomization(true)}
                    >
                      Edit
                    </S.CustomizationAction>
                    <S.CustomizationAction
                      onClick={() => setCustomizationValues(null)}
                    >
                      Remove
                    </S.CustomizationAction>
                  </S.CustomizationButtonsContainer>
                )}
              {![
                fetchStatusesBFields.FETCHING,
                fetchStatusesBFields.ERROR,
              ].includes(fetchStatusBFields) &&
                !customizationValues && (
                  <S.Button
                    colorOption="black"
                    label="CUSTOMIZE"
                    onClick={() => setShowCustomization(true)}
                  ></S.Button>
                )}
            </S.CustomizationCard>
          </S.Customization>
        )}
      {showCustomization && (
        <S.Customize>
          <S.Panel>
            <S.Form>
              <S.Row>
                <S.FormTitle>MAKE IT YOURS</S.FormTitle>
                <S.Button
                  colorOption="transparent"
                  icon="Close"
                  onClick={() => setShowCustomization(false)}
                />
              </S.Row>
              <S.Wrap>
                <S.Column>
                  {boxfields.dealerName && (
                    <Input
                      id="dealerName"
                      name="dealerName"
                      type="dealerName"
                      label="Name"
                      value={values.dealerName}
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('dealerName', option.target.value)
                      }}
                      invalid={!!touched.dealerName && !!errors.dealerName}
                      invalidMessage={errors.dealerName as string}
                      onBlur={() => {
                        setFieldTouched('dealerName')
                      }}
                    />
                  )}
                  {boxfields.dealerAddress && (
                    <Input
                      id="dealerAddress"
                      name="dealerAddress"
                      type="dealerAddress"
                      value={values.dealerAddress}
                      label="Address Line 1"
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('dealerAddress', option.target.value)
                      }}
                      invalid={
                        !!touched.dealerAddress && !!errors.dealerAddress
                      }
                      invalidMessage={errors.dealerAddress as string}
                      onBlur={() => {
                        setFieldTouched('dealerAddress')
                      }}
                    />
                  )}
                  {boxfields.dealerAddress2 && (
                    <Input
                      id="dealerAddress2"
                      name="dealerAddress2"
                      type="dealerAddress2"
                      value={values.dealerAddress2}
                      label="Address Line 2"
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('dealerAddress2', option.target.value)
                      }}
                      invalid={
                        !!touched.dealerAddress2 && !!errors.dealerAddress2
                      }
                      invalidMessage={errors.dealerAddress2 as string}
                      onBlur={() => {
                        setFieldTouched('dealerAddress2')
                      }}
                    />
                  )}
                  {boxfields.dealerPhone && (
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="phoneNumber"
                      value={values.phoneNumber}
                      label="Phone Number"
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('phoneNumber', option.target.value)
                      }}
                      invalid={!!touched.phoneNumber && !!errors.phoneNumber}
                      invalidMessage={errors.phoneNumber as string}
                      onBlur={() => {
                        setFieldTouched('phoneNumber')
                      }}
                    />
                  )}
                  {boxfields.dealerImage && (
                    <>
                      <Input
                        textarea={false}
                        id="dealerImage"
                        name="dealerImage"
                        type="file"
                        value={undefined}
                        label="Image"
                        //eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(event: any) => {
                          setDealerImage(event.target.files[0])
                          setFieldValue('dealerImage', event.target.files[0])
                        }}
                        accept="image/*"
                        invalid={!!touched.dealerImage && !!errors.dealerImage}
                        invalidMessage={errors.dealerImage as string}
                        onBlur={() => {
                          setFieldTouched('dealerImage')
                        }}
                      />
                      <S.Instructions>
                        {channel.selectedChannel.toLowerCase() === 'isp'
                          ? 'Note: the uploaded dealer image must be in an aspect ratio of 4:1. This can be calculated by dividing the width of the image, in pixels, by its height, also in pixels.'
                          : 'Note: the uploaded dealer image must be in an aspect ratio of 1:1, or perfectly square.'}
                      </S.Instructions>
                    </>
                  )}
                  <S.Button
                    colorOption="black"
                    label="SAVE CHANGES"
                    onClick={() => {
                      validateForm().then((errors) => {
                        setTouched(setNestedObjectValues(errors, true))
                      })

                      submitForm()
                    }}
                  />
                </S.Column>
              </S.Wrap>
            </S.Form>
          </S.Panel>
        </S.Customize>
      )}
      <S.ButtonsContainer>
        <S.Button
          colorOption="blue"
          label={addToCartText}
          onClick={() => {
            addProduct(cartProduct)
            setShowDrawer(true)
          }}
        />
        {product.type === ProductType.CREATIVE &&
          fetchStatusesBFields.SUCCESS === fetchStatusBFields && (
            <>
              <S.PreviewButton
                onClick={() => {
                  const {
                    dealerName,
                    phoneNumber,
                    dealerAddress,
                    dealerAddress2,
                  } = values

                  const payload: Partial<CompsPayload> = {
                    uri:
                      (product &&
                        product.variants[selectedVariant] &&
                        product?.variants[selectedVariant].inDesignFile) ??
                      ' ',
                    channel: channel.selectedChannel,
                    isPreferred: isNonEmptyArray(
                      channel.associatedMasterDealerIds[
                        selectedMasterDealerIndex
                      ].dealerTypes
                    )
                      ? channel.associatedMasterDealerIds[
                          selectedMasterDealerIndex
                        ].dealerTypes!.includes(DealerType.PREFERRED_DEALER)
                        ? 'true'
                        : 'false'
                      : 'false',
                  }

                  payload.dealerName = ' '
                  payload.dealerPhone = ' '
                  payload.dealerAddress = ' '
                  payload.dealerAddress2 = ' '
                  payload.dealerImage = dealerImage

                  dealerName && (payload.dealerName = dealerName)
                  phoneNumber && (payload.dealerPhone = phoneNumber)
                  dealerAddress && (payload.dealerAddress = dealerAddress)
                  dealerAddress2 && (payload.dealerAddress2 = dealerAddress2)
                  dealerImage && (payload.dealerImage = dealerImage)

                  const isSamePayload = isSameCompsPayload(payload)

                  if (isSamePayload) return toggleModal()

                  setCurrentCompsPayload(payload)
                  getComps(payload)
                  toggleModal()
                }}
              >
                Preview
              </S.PreviewButton>
              <S.Dialog
                aria-label="Preview"
                isOpen={showPreviewModal}
                onDismiss={toggleModal}
              >
                <S.CloseModalButton
                  colorOption="delicateGrey"
                  icon="Close"
                  borderRadius
                  size="large"
                  onClick={toggleModal}
                />
                {fetchStatusesComps.FETCHING === fetchStatusComps && (
                  <S.SpinnerContainer>
                    <Spinner spinnerSize={8} />
                  </S.SpinnerContainer>
                )}
                {fetchStatusesComps.ERROR === fetchStatusComps &&
                  'Could not retrive preview images, please try again'}
                {fetchStatusesComps.SUCCESS === fetchStatusComps && (
                  <Slider
                    arrows={false}
                    dots
                    infinite
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    {previewImages.map((media, index) => (
                      <S.CaroselImage key={`image-${index}`}>
                        <img src={media} alt={`${index}`} />
                      </S.CaroselImage>
                    ))}
                  </Slider>
                )}
              </S.Dialog>
            </>
          )}
      </S.ButtonsContainer>
      <S.CartDrawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <SectionCartList onClose={() => setShowDrawer(false)} />
      </S.CartDrawer>
    </S.Wrap>
  )
}

export default ProductDetail
