import * as S from './styles'

import {
  useChannel,
  useCartContext,
  http,
  NotificationHelper,
  KeycloakHelper,
  useImpersonatedUser,
} from '@monorepo/infra'
import React, { useCallback, useEffect, useState, useRef } from 'react'

import { Icon } from '@monorepo/components'
import NavItem from '../NavItem'
import { colors } from '@monorepo/theme'
import thumbAdMaterials from '../../../assets/menu/AdMaterials.png'
import thumbMedia from '../../../assets/menu/Media.png'
import thumbMicrosites from '../../../assets/menu/Microsites.png'
import { useHistory } from 'react-router-dom'
import ProfileNavigation from '../ProfileNavigation'
import EventSource from 'eventsource'
import { useKeycloak } from '@react-keycloak/web'
import { LogItemType } from '@monorepo/interfaces'

export interface NavItemsChildrenInterface {
  route: string
  name: string
  image: string
  channels?: string[]
  logItemType?: LogItemType
}

export interface NavItemsInterface {
  route: string
  name: string
  children?: NavItemsChildrenInterface[]
  channels?: string[]
  logItemType?: LogItemType
}

const navItems: NavItemsInterface[] = [
  {
    route: '',
    name: 'Creative',
    children: [
      {
        name: 'Ad Materials',
        route: '/all-products',
        image: thumbAdMaterials,
      },
      {
        name: 'Media',
        route: '/media',
        image: thumbMedia,
        channels: ['LSP', 'D2D', 'EM', 'MDU'],
      },
      {
        name: 'Microsites',
        route: '/microsites',
        image: thumbMicrosites,
        channels: ['LSP', 'D2D', 'EM', 'MDU', 'ISP'],
      },
    ],
  },
  {
    route: '/all-events',
    name: 'Events',
    channels: ['LSP', 'D2D', 'MDU'],
  },
  {
    route: '/merchandise',
    name: 'Merchandise',
    channels: ['LSP', 'D2D', 'MDU', 'EM', 'ISP'],
  },
  {
    route: '/resources',
    name: 'Resources',
  },
  {
    route: '/demoapp',
    name: 'DIRECTV DEMO',
    channels: ['LSP', 'D2D', 'MDU', 'EM', 'ISP'],
    logItemType: LogItemType.DIRECTV_DEMO,
  },
]

const Header: React.FC = () => {
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)
  const [filteredNavItems, setFilteredNavItems] = useState(navItems)
  const history = useHistory()
  const { selectedChannel } = useChannel()
  const [showProfileNavigation, setShowProfileNavigation] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)
  const { name: impersonatedUserName } = useImpersonatedUser()
  const [impersonatedUser, setImpersonatedUser] = useState<
    string | null | undefined
  >()

  const profileNavigationButtonRef = useRef<HTMLButtonElement>(null)
  const { items } = useCartContext()

  useEffect(() => {
    if (!impersonatedUserName) {
      return
    }
    setImpersonatedUser(impersonatedUserName)
  }, [impersonatedUserName])

  useEffect(() => {
    const newNavItems = navItems
      .filter((navItem) => {
        return (
          !!selectedChannel &&
          (!navItem.channels ||
            (navItem.channels && navItem.channels.includes(selectedChannel)))
        )
      })
      .map((navItem) => {
        if (!navItem.children) return navItem
        return {
          ...navItem,
          children: navItem.children.filter(
            (child) =>
              !child.channels || child.channels.includes(selectedChannel)
          ),
        }
      })
    setFilteredNavItems(newNavItems)
  }, [selectedChannel])

  const checkProfileNavigationOutsideClick = (event: MouseEvent) => {
    if (profileNavigationButtonRef.current?.contains(event.target as Node)) {
      return
    }

    hideProfileNavigation()
  }

  const hideProfileNavigation = () => {
    setShowProfileNavigation(false)
  }

  const toogleProfileNavigation = () => {
    setShowProfileNavigation((showProfileNavigation) => !showProfileNavigation)
  }

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      checkProfileNavigationOutsideClick(event)
    }

    const handleWindowScroll = () => {
      hideProfileNavigation()
    }

    document.addEventListener('click', handleDocumentClick)
    window.addEventListener('scroll', handleWindowScroll)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [])

  const handleProfileNavigationClick = () => {
    toogleProfileNavigation()
  }

  const getNotificationCount = useCallback(() => {
    http
      .get<number>({
        url: `/notification/count?startDate=${NotificationHelper.getStartDate().toISOString()}`,
      })
      .then(({ data }) => {
        if (data) {
          setNotificationCount(data)
        } else {
          setNotificationCount(0)
        }
      })
  }, [])

  useEffect(() => {
    getNotificationCount()
  }, [getNotificationCount])

  useEffect(() => {
    if (keycloak.authenticated) {
      const sse = new EventSource(
        `${process.env.REACT_APP_REST_BASE_URL}/sse/events`,
        {
          headers: {
            Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
          },
        }
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sse.onmessage = (e: any) => {
        // if (e.data.recipients.includes(user?.email)) {
        //   getNotificationCount()
        // }
        const data = JSON.parse(e.data)
        if (data.recipients && data.recipients.includes(user?.email)) {
          getNotificationCount()
        }
      }
    }
  }, [
    getNotificationCount,
    keycloak.authenticated,
    KeycloakHelper.getToken(keycloak),
    user?.email,
  ])

  return (
    <S.HeaderContainer>
      <S.LogoContainer to="/">
        <img src="/images/logo.svg" alt="logo" />
      </S.LogoContainer>
      <S.NavItemsContainer>
        {filteredNavItems.map((item, index) => (
          <NavItem item={item} key={index}></NavItem>
        ))}
      </S.NavItemsContainer>
      <S.NavToolsContainer>
        {/* <Search /> */}
        <S.HeaderButton
          ref={profileNavigationButtonRef}
          type="button"
          onClick={() => handleProfileNavigationClick()}
        >
          <Icon
            icon="Profile"
            size={24}
            hasStroke={false}
            color={colors.white}
            counter={notificationCount}
          />
          <ProfileNavigation show={showProfileNavigation}></ProfileNavigation>
        </S.HeaderButton>
        <S.HeaderButton type="button" onClick={() => history.push('/cart')}>
          <Icon
            icon="Cart"
            counter={
              items.products.length || items.events.length
                ? items.products.length + items.events.length
                : 0
            }
            size={31}
            hasStroke={false}
            color={colors.white}
          />
        </S.HeaderButton>
        {!!impersonatedUser ? (
          <S.UserName color="red">{impersonatedUser}</S.UserName>
        ) : undefined}
      </S.NavToolsContainer>
    </S.HeaderContainer>
  )
}

export default Header
