import * as S from './styles'

import React, { useEffect, useState } from 'react'
import { TabPanel, TabPanels, Tabs } from '@reach/tabs'

import MyAccount from '../../../pages/my-account'
import { RegistrationUserResponse } from '../Registration'
import TeamMembers from '../TeamMembers'
import { httpUserInfo, UserHelper } from '@monorepo/infra'
import { Dealer, DealerResponse } from '@monorepo/interfaces'
import XLSX from 'sheetjs-style'
import { saveAs } from 'file-saver'

interface ProfileTemplateProps {
  profile: RegistrationUserResponse | null
}

interface TabsInterface {
  name: string
}

interface MemberExportModel {
  firstName: string
  lastName: string
  email: string
  channels: string
  dealerType: string
  hasCoop: string
}

const ProfileTemplate: React.FC<ProfileTemplateProps> = ({ profile }) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  const profileTab = { name: 'Profile' }
  const teammembersTab = { name: 'Team Members' }
  const [tabs, setTabs] = useState<TabsInterface[]>([profileTab])
  const [creatingMembersExport, setCreatingMembersExport] = useState(false)

  const masterDealerId =
    profile?.dealer?.associatedMasterDealers[0]?.masterDealerId

  const dialog = {
    showEditDialog,
    showCreateDialog,
    showDeleteDialog,
    setShowEditDialog,
    setShowCreateDialog,
    setShowDeleteDialog,
  }

  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleChange = (index: number) => {
    setSelectedIndex(index)
  }

  const getDealerType = (dealerGroup: string) => {
    switch (dealerGroup) {
      case '/dealerAdmin':
      case 'dealerAdmin':
        return 'Admin'
      case '/dealer':
      case 'dealer':
        return 'Member'
      default:
        return 'Unknown'
    }
  }

  const mapDealerToMemberExportModel = (dealer: Dealer): MemberExportModel => {
    return {
      firstName: dealer.firstName ?? '',
      lastName: dealer.lastName ?? '',
      email: dealer.email ?? '',
      dealerType: getDealerType(dealer.groups?.[0] as string),
      channels:
        dealer?.dealer?.associatedMasterDealers[0].channels?.join(',') ?? '',
      hasCoop: dealer?.dealer?.hasCoop?.toString() ?? '',
    }
  }

  const handleExportMembersClick = async () => {
    if (creatingMembersExport) {
      return
    }

    setCreatingMembersExport(true)

    try {
      await exportMembersAsync()
    } catch (error) {
      console.error(error)
    }

    setCreatingMembersExport(false)
  }

  const exportMembersAsync = async () => {
    const dealers = await loadAllDealersForMasterDealerAsync()

    if (!dealers || dealers.length === 0) {
      return
    }

    const memberExportModels = dealers.map((x) =>
      mapDealerToMemberExportModel(x)
    )

    const worksheet = XLSX.utils.json_to_sheet(memberExportModels)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TeamMembers')
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(blob, 'ExportTeamMembers.xlsx')
  }

  const loadAllDealersForMasterDealerAsync = async () => {
    if (!masterDealerId) {
      return
    }

    const baseApiURL = `/dealer/bymasterdealer/${masterDealerId}`

    const getPagingUrlParams = (page: number, pageSize: number) => {
      return `page=${page}&pageSize=${pageSize}`
    }

    const getNextApiUrl = (page: number, pageSize: number) => {
      const pagingUrlParams = getPagingUrlParams(page, pageSize)
      return `${baseApiURL}?${pagingUrlParams}`
    }

    const loadFirstChunkAsync = async () => {
      try {
        const apiUrl = getNextApiUrl(1, 100)
        const response = await httpUserInfo.get<DealerResponse>({ url: apiUrl })

        if (!response || !response?.data) {
          return null
        }

        return {
          dealersChunk: response.data.data,
          page: response.data.page,
          maxPageSize: response.data.maxPageSize,
        }
      } catch (error) {
        console.error(error)
        return null
      }
    }

    const loadChunkAsync = async (page: number, pageSize: number) => {
      try {
        const apiUrl = getNextApiUrl(page, pageSize)
        const response = await httpUserInfo.get<DealerResponse>({ url: apiUrl })

        if (!response || !response?.data) {
          return null
        }

        return { dealersChunk: response.data.data, page: response.data.page }
      } catch (error) {
        console.error(error)
        return null
      }
    }

    const firstChunkRes = await loadFirstChunkAsync()

    if (firstChunkRes == null || firstChunkRes.dealersChunk.length === 0) {
      return null
    }

    const dealerChunks = []
    const pageSize = firstChunkRes.maxPageSize
    let page = firstChunkRes.page + 1
    let dealersChunk = firstChunkRes.dealersChunk

    while (dealersChunk.length > 0) {
      dealerChunks.push(dealersChunk)
      const chunkRes = await loadChunkAsync(page, pageSize)

      if (chunkRes == null) {
        break
      }

      dealersChunk = chunkRes.dealersChunk
      page = chunkRes.page + 1
    }

    return dealerChunks.flat()
  }

  useEffect(() => {
    if (profile?.groups?.length) {
      const isAdmin = UserHelper.isDealerAdmin(profile?.groups)
      setIsAdmin(isAdmin)
      if (isAdmin) {
        setTabs([profileTab, teammembersTab])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  return (
    <S.Container>
      <S.Header>
        <S.Title>MY ACCOUNT</S.Title>
      </S.Header>

      <Tabs onChange={(index) => handleChange(index)}>
        <S.Wrapper>
          <S.TabList>
            {tabs.map((tab, index) => (
              <S.Tab
                selected={index === selectedIndex}
                index={index}
                key={index}
              >
                {tab?.name}
              </S.Tab>
            ))}
          </S.TabList>
          {selectedIndex === 1 && isAdmin && (
            <S.ButtonRow>
              <S.Button
                label={creatingMembersExport ? 'CREATING EXPORT...' : 'EXPORT'}
                onClick={() => handleExportMembersClick()}
              />
              <S.Button
                label="ADD TEAM MEMBER"
                onClick={() => setShowCreateDialog(true)}
              />
            </S.ButtonRow>
          )}
        </S.Wrapper>

        <TabPanels>
          <TabPanel>
            <MyAccount profile={profile} />
          </TabPanel>
          <TabPanel>
            <TeamMembers isAdmin={isAdmin} profile={profile} dialog={dialog} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </S.Container>
  )
}

export default ProfileTemplate
