import { DealerType, http, isNonEmptyArray, useChannel } from '@monorepo/infra'
import { useEffect, useState } from 'react'

interface UseBoxFieldsProps {
  uri: string
}

const fetchStatuses = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

interface UseBoxFields {
  boxfields: {
    dealerName: boolean
    dealerAddress: boolean
    dealerAddress2: boolean
    dealerPhone: boolean
    dealerImage: boolean
  }
  fetchStatuses: typeof fetchStatuses
  fetchStatus: string
  shouldShowSomeBoxfield: boolean
}

export const useBoxFields = ({ uri }: UseBoxFieldsProps): UseBoxFields => {
  const channel = useChannel()
  const [selectedMasterDealerIndex, setSelectedMasterDealerIndex] = useState(0)
  const [isPreferred, setIsPreferred] = useState('false')
  useEffect(() => {
    let newIndex = 0
    if (
      channel.associatedMasterDealerIds &&
      channel.associatedMasterDealerIds.length > 0
    ) {
      newIndex = channel.associatedMasterDealerIds.findIndex(
        (amd) => amd.masterDealerId === channel.selectedMasterDealerId
      )
    }
    setSelectedMasterDealerIndex(newIndex > 0 ? newIndex : 0)
  }, [channel.associatedMasterDealerIds, channel.selectedMasterDealerId])
  useEffect(() => {
    if (
      channel.associatedMasterDealerIds &&
      channel.associatedMasterDealerIds[selectedMasterDealerIndex] &&
      isNonEmptyArray(
        channel.associatedMasterDealerIds[selectedMasterDealerIndex].dealerTypes
      )
    ) {
      if (
        channel.associatedMasterDealerIds[
          selectedMasterDealerIndex
        ].dealerTypes!.includes(DealerType.PREFERRED_DEALER)
      ) {
        setIsPreferred('true')
        return
      }
    }
    setIsPreferred('false')
  }, [channel.associatedMasterDealerIds, selectedMasterDealerIndex])

  const [boxfields, setBoxfields] = useState<UseBoxFields['boxfields']>({
    dealerAddress: false,
    dealerAddress2: false,
    dealerName: false,
    dealerPhone: false,
    dealerImage: false,
  })

  const [fetchStatus, setFetchStatus] = useState<UseBoxFields['fetchStatus']>(
    fetchStatuses.INITIAL
  )

  useEffect(() => {
    async function getBoxFields() {
      if (!channel.selectedChannel || !uri) {
        setBoxfields({
          dealerAddress: false,
          dealerAddress2: false,
          dealerName: false,
          dealerPhone: false,
          dealerImage: false,
        })
        setFetchStatus(fetchStatuses.INITIAL)
        return
      }

      const searchParams = new URLSearchParams({
        dealerChannel: channel.selectedChannel,
        uri,
        isPreferred,
      })

      try {
        setFetchStatus(fetchStatuses.FETCHING)

        const boxfieldsResponse = await http.get({
          url: '/indesign/boxfield',
          params: searchParams,
        })

        const boxfields = boxfieldsResponse.data as UseBoxFields['boxfields']

        setBoxfields(boxfields)
        setFetchStatus(fetchStatuses.SUCCESS)
      } catch (error) {
        setFetchStatus(fetchStatuses.ERROR)
      }
    }

    getBoxFields()
  }, [
    channel.associatedMasterDealerIds,
    channel.selectedChannel,
    isPreferred,
    selectedMasterDealerIndex,
    uri,
  ])

  return {
    boxfields,
    fetchStatuses,
    fetchStatus,
    shouldShowSomeBoxfield: Object.values(boxfields).some(Boolean),
  }
}
