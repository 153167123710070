import {
  Tab as ReachTab,
  TabList as ReachTabList,
  TabProps as ReachTabProps,
} from '@reach/tabs'
import styled from 'styled-components'
import { Search as BaseSearch, SearchProps } from '@monorepo/components'
import { colors } from '@monorepo/theme'

export const Form = styled.div`
  width: 100%;
  max-width: 922px;
  margin: 1rem auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Container = styled.section`
  min-height: calc(100vh - 100px - 76px);
`

export const Header = styled.header`
  width: 100%;
  padding: 34px;
  padding-top: 108px;
`

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 500;
`

export const TabList = styled(ReachTabList)`
  background: unset;
  border-bottom: 1px solid #d9dbdd;
  padding: 0px 34px 18px 34px;
  gap: 23px;
`

interface TabProps extends ReachTabProps {
  selected?: boolean
}

export const Tab = styled(ReachTab)<TabProps>`
  height: fit-content;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  color: ${({ selected }) => (selected ? '#000000' : '#8793A0')};
`

export const SearchContainer = styled.p`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
`

export const Search = styled(BaseSearch).attrs({
  backgroundColor: colors.delicateGrey,
  width: 325,
  height: 36,
})<SearchProps>``

export const ToolbarContainer = styled.div`
  display: flex;
`

export const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
`

export const SelectAllLabel = styled.span`
  padding-right: 20px;
`

export const SelectAllButton = styled.span`
  cursor: pointer;
  padding: 0 10px;
  background-color: ${colors.lightGrey};
  border-radius: 20px;
`
