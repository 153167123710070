import * as S from './styles'
import {
  CoopAllowance,
  Dealer,
  IReactSelectOption,
  IDealerForm,
  IDealerFormInitialValuesParsed,
} from '@monorepo/interfaces'
import {
  ChannelHelper,
  MasterDealerAssociation,
  useChannel,
  DealerHelper,
  emptyReactSelectOption,
  CoopAllowanceCapabilityType,
  formatterCurrencyUSD,
  isNonEmptyArray,
  isObject,
  isStringEqualCI,
  UserHelper,
  isNonEmptyString,
} from '@monorepo/infra'
import { TableBody } from '@material-ui/core'

import { Spinner } from '@monorepo/components'
import { DealerForm } from './forms/dealer'
import { useState } from 'react'
import { colors } from '@monorepo/theme'

export interface SelectableDealer extends Dealer {
  selected?: boolean
}

export interface SectionTeamMembersListProps {
  dealers: Dealer[] | SelectableDealer[]
  loading: boolean
  createDealer: (data: IDealerForm) => void
  deleteDealer: (userId: string, dealerId: string, email?: string) => void
  editDealer: (data: IDealerForm) => void
  isAdmin: boolean
  dialog: {
    showEditDialog: boolean
    showCreateDialog: boolean
    showDeleteDialog: boolean
    setShowEditDialog: (toggle: boolean) => void
    setShowCreateDialog: (toggle: boolean) => void
    setShowDeleteDialog: (toggle: boolean) => void
  }
  error: string
  principalEmails?: string[]
  selectable?: boolean
  selectOne?: (id: string, selected: boolean) => void
}

const dealerType = (dealerGroups?: string) =>
  isNonEmptyString(dealerGroups)
    ? dealerGroups?.replace('Dealer Admin', 'Admin').replace('Dealer', 'Member')
    : ''

const channelsList = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  associatedMasterDealers: any,
  coopAllowances: CoopAllowance[]
) => {
  if (
    !Array.isArray(associatedMasterDealers) ||
    !associatedMasterDealers.length
  )
    return null

  return (
    <S.ChannelsListContainer>
      {associatedMasterDealers.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (associatedMasterDealer: any, index: number) => {
          const channels = associatedMasterDealer.channels
          if (!Array.isArray(channels) || !channels.length) return null

          const masterDealerId = associatedMasterDealer.masterDealerId

          return (
            <S.WrapTags key={index}>
              {channels.map((channel: string, index: number) => {
                return (
                  <S.Tag
                    key={index}
                    label={channel}
                    backgroundColor={
                      coopAllowances?.find(
                        (item) =>
                          item.masterDealerId.trim().toUpperCase() ===
                            ChannelHelper.getMasterDealerId(
                              masterDealerId.trim().toUpperCase(),
                              channel.trim().toUpperCase()
                            ) &&
                          !isNaN(item?.allowance as number) &&
                          (item?.allowance as number) > 0
                      )
                        ? colors.green
                        : colors.lapis
                    }
                  />
                )
              })}
            </S.WrapTags>
          )
        }
      )}
    </S.ChannelsListContainer>
  )
}

const fillStatus = (groups: string[]) => {
  const hasDealerAdmin =
    groups.includes('/dealerAdmin') || groups.includes('dealerAdmin')
  const hasDealer = groups.includes('/dealer') || groups.includes('dealer')

  if (hasDealerAdmin || (hasDealerAdmin && hasDealer)) {
    return { value: '/dealerAdmin', label: 'Admin' }
  }

  if (hasDealer) {
    return { value: '/dealer', label: 'Member' }
  }

  return emptyReactSelectOption
}

const clockwiseOpenCircleArrow = '\u21bb'

const getCoopAllowanceText = (coopAllowance?: CoopAllowance, index?: number) =>
  !isNaN(coopAllowance?.allowance as number) &&
  (coopAllowance!.allowance as number) > 0 ? (
    <S.CoopAllowanceTextContainer key={index}>{`${
      ChannelHelper.splitMasterDealerId(coopAllowance!.masterDealerId)?.channel
    } ${formatterCurrencyUSD.format(coopAllowance!.allowance as number)}${
      coopAllowance?.capability ===
      CoopAllowanceCapabilityType.MONTHLY_RECURRING
        ? ` ${clockwiseOpenCircleArrow}`
        : ''
    }${
      coopAllowance?.capability ===
        CoopAllowanceCapabilityType.MONTHLY_RECURRING &&
      !isNaN(coopAllowance?.recurringAllowance as number) &&
      (coopAllowance!.recurringAllowance as number) > 0
        ? ` ${formatterCurrencyUSD.format(
            coopAllowance!.recurringAllowance as number
          )}`
        : ''
    }`}</S.CoopAllowanceTextContainer>
  ) : undefined

const getCoopAllowancesText = (coopAllowances?: CoopAllowance[]) =>
  Array.isArray(coopAllowances) && coopAllowances.length ? (
    <S.CoopAllowancesTextContainer>
      {coopAllowances.map((coopAllowance: CoopAllowance, index: number) =>
        getCoopAllowanceText(coopAllowance, index)
      )}
    </S.CoopAllowancesTextContainer>
  ) : undefined

const SectionTeamMembersList: React.FC<SectionTeamMembersListProps> = ({
  loading = true,
  dialog,
  dealers,
  createDealer,
  deleteDealer,
  editDealer,
  isAdmin,
  error,
  principalEmails,
  selectable,
  selectOne,
}) => {
  const [deleteUser, setDeleteUser] = useState({
    name: '',
    dealerId: '',
    userId: '',
    email: '',
  })
  const [editUser, setEditUser] = useState<IDealerFormInitialValuesParsed>({
    firstName: '',
    lastName: '',
    email: '',
    channels: null,
    types: null,
    status: emptyReactSelectOption,
    hasCoop: false,
    userId: '',
    dealerId: '',
    coopAllowances: undefined,
    uniqueDealerId: undefined,
    businessName: undefined,
  })
  const [isEditUserPrincipal, setIsEditUserPrincipal] = useState<boolean>(false)
  const [availableChannelOptions, setAvailableChannelOptions] = useState<
    { value: string; label: string }[]
  >([])
  const { associatedMasterDealerIds, selectedMasterDealerId } = useChannel()

  const channelsParsed = (channels?: string[]) => {
    return isNonEmptyArray(channels)
      ? channels!.map((channel) => ({
          value: channel.toLowerCase(),
          label: channel.toUpperCase(),
        }))
      : undefined
  }

  const typesParsed = (types?: string[]) => {
    return isNonEmptyArray(types)
      ? types!.map((type) => ({
          value: type,
          label: type,
        }))
      : undefined
  }

  const getAvailableChannelOptions = (
    associatedMasterDealers: MasterDealerAssociation[],
    masterDealerId?: string
  ): IReactSelectOption[] => {
    const availableChannels = ChannelHelper.getMasterDealerChannels(
      associatedMasterDealers,
      masterDealerId
    )

    return ChannelHelper.getChannelOptions().filter((option) =>
      availableChannels.find((availableChannel) =>
        isStringEqualCI(availableChannel, option.value)
      )
    )
  }

  const handleEditTeamMember = (index: number) => {
    const id = dealers[index]._id
    const dealer = dealers.find((user: Dealer) => isStringEqualCI(user._id, id))
    if (!isObject(dealer)) {
      return
    }

    const channels = ChannelHelper.getChannels(
      dealer!.dealer?.associatedMasterDealers as MasterDealerAssociation[],
      selectedMasterDealerId
    )
    const isPrincipal = DealerHelper.isPrincipal(dealer, principalEmails)

    setEditUser({
      firstName: dealer!.firstName as string,
      lastName: dealer!.lastName as string,
      email: dealer!.email?.trim()?.toLowerCase() || '',
      channels: channelsParsed(channels) as IReactSelectOption[],
      types: typesParsed(dealer!.dealerTypes ?? []) as IReactSelectOption[],
      status: fillStatus(
        isPrincipal ? ['/dealerAdmin'] : (dealer!.groups as string[])
      ),
      hasCoop: dealer!.dealer?.hasCoop || isPrincipal,
      dealerId: dealer!.dealer?._id as string,
      userId: dealer!._id as string,
      coopAllowances: dealer!.dealer?.coopAllowances,
      uniqueDealerId: dealer!.dealer?.uniqueDealerId,
      businessName: dealer!.dealer?.businessName,
      enabled: dealer!.enabled,
    } as IDealerFormInitialValuesParsed)

    setAvailableChannelOptions(
      getAvailableChannelOptions(
        dealer!.dealer?.associatedMasterDealers as MasterDealerAssociation[],
        selectedMasterDealerId
      )
    )
    setIsEditUserPrincipal(isPrincipal)

    dialog.setShowEditDialog(true)
  }

  const handleDeleteTeamMember = (index: number) => {
    const id = dealers[index]._id
    const dealer = dealers.find((user: Dealer) => user._id === id)
    if (!dealer) {
      return
    }

    if (DealerHelper.isPrincipal(dealer, principalEmails)) {
      alert("A principal can't be removed.")
      return
    }

    setDeleteUser({
      userId: dealer._id as string,
      dealerId: dealer.dealer?._id as string,
      name: `${dealer.firstName} ${dealer.lastName}`,
      email: dealer.email as string,
    })
    dialog.setShowDeleteDialog(true)
  }

  const close = () => {
    dialog.setShowCreateDialog(false)
    dialog.setShowEditDialog(false)
    dialog.setShowDeleteDialog(false)
  }

  return (
    <S.Container>
      <S.TableContainer>
        <S.Table aria-label="Products Table">
          <TableBody>
            {loading ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : dealers.length === 0 ? (
              <S.EmptyMessage>
                There are no team members available or you are the only member
                of your team. Please check your search criteria if one was
                entered.
              </S.EmptyMessage>
            ) : (
              dealers?.map((dealer: SelectableDealer, index: number) => (
                <S.TbodyRow key={index}>
                  {selectable ? (
                    <S.TBodyCell scope="row">
                      <S.CheckboxContainer>
                        <input
                          type="checkbox"
                          key={dealer._id}
                          checked={dealer.selected}
                          onChange={(e) =>
                            selectOne?.(dealer._id as string, e.target.checked)
                          }
                        />
                      </S.CheckboxContainer>
                    </S.TBodyCell>
                  ) : undefined}
                  <S.TBodyCell scope="row">
                    <S.UserProfileBadge>
                      {dealer?.firstName?.charAt(0) ?? 'T'}
                    </S.UserProfileBadge>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.Name>{`${dealer.firstName} ${dealer.lastName}`}</S.Name>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.Email>{dealer.email}</S.Email>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {channelsList(
                      dealer.dealer?.associatedMasterDealers,
                      dealer.dealer?.coopAllowances as CoopAllowance[]
                    )}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.Group>
                      {DealerHelper.isPrincipal(
                        dealer,
                        principalEmails,
                        selectedMasterDealerId
                      )
                        ? 'Principal'
                        : dealerType(
                            UserHelper.getUserGroupsOptions(dealer.groups)
                              ?.map((item) => item.label)
                              .join()
                          )}
                    </S.Group>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {getCoopAllowancesText(dealer.dealer?.coopAllowances)}
                  </S.TBodyCell>
                  {isAdmin && (
                    <S.TBodyCell
                      scope="row"
                      onClick={() => handleEditTeamMember(index)}
                    >
                      <S.Link>Edit</S.Link>
                    </S.TBodyCell>
                  )}
                  {isAdmin && (
                    <S.TBodyCell
                      scope="row"
                      onClick={() => handleDeleteTeamMember(index)}
                    >
                      <S.Link>Remove</S.Link>
                    </S.TBodyCell>
                  )}
                </S.TbodyRow>
              ))
            )}
          </TableBody>
        </S.Table>
      </S.TableContainer>
      <S.TeamMembersDialog
        aria-label="Dialog"
        isOpen={dialog.showEditDialog}
        onDismiss={close}
      >
        <S.DialogContent aria-label="Content">
          <DealerForm
            initialValues={editUser}
            actionForm={editDealer}
            close={close}
            error={''}
            masterDealerId={selectedMasterDealerId}
            availableChannelOptions={availableChannelOptions}
            isPrincipal={isEditUserPrincipal}
          />
        </S.DialogContent>
      </S.TeamMembersDialog>
      <S.TeamMembersDialog
        aria-label="Dialog"
        isOpen={dialog.showCreateDialog}
        onDismiss={close}
      >
        <S.DialogContent aria-label="Content">
          <DealerForm
            actionForm={createDealer}
            close={close}
            error={error}
            masterDealerId={selectedMasterDealerId}
            availableChannelOptions={getAvailableChannelOptions(
              associatedMasterDealerIds,
              selectedMasterDealerId
            )}
          />
        </S.DialogContent>
      </S.TeamMembersDialog>
      <S.TeamMembersDialog
        aria-label="Dialog"
        isOpen={dialog.showDeleteDialog}
        onDismiss={close}
      >
        <S.DialogContent aria-label="Content">
          <h2>{`Are you sure you want to delete the user ${deleteUser.name} ?`}</h2>
          <S.DialogActions>
            <S.ModalButton
              colorOption="stroke"
              label="CANCEL"
              onClick={() => close()}
            />
            <S.ModalButton
              colorOption="black"
              label="Remove user"
              onClick={() => {
                const { userId, dealerId, email } = deleteUser
                deleteDealer(userId, dealerId, email)
                close()
              }}
            />
          </S.DialogActions>
        </S.DialogContent>
      </S.TeamMembersDialog>
    </S.Container>
  )
}

export default SectionTeamMembersList
