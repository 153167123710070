import * as S from '../styles'

import {
  ProductHelper,
  http,
  useChannel,
  DealerType,
  isNonEmptyArray,
} from '@monorepo/infra'
import React, { useEffect, useState } from 'react'

import { CartProduct } from '@monorepo/interfaces'
import { Spinner } from '@monorepo/components'

interface ProductItemProps {
  item: CartProduct
}

export const ProductItem: React.FC<ProductItemProps> = ({ item }) => {
  const channel = useChannel()
  const [selectedMasterDealerIndex, setSelectedMasterDealerIndex] = useState(0)
  useEffect(() => {
    let newIndex = 0
    if (
      channel.associatedMasterDealerIds &&
      channel.associatedMasterDealerIds.length > 0
    ) {
      newIndex = channel.associatedMasterDealerIds.findIndex(
        (amd) => amd.masterDealerId === channel.selectedMasterDealerId
      )
    }
    setSelectedMasterDealerIndex(newIndex > 0 ? newIndex : 0)
  }, [channel.associatedMasterDealerIds, channel.selectedMasterDealerId])
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [download, setDownload] = useState<string | null>(null)
  const [hasInDesign, setHasInDesign] = useState(false)
  const {
    selectedVariantIndex,
    selectedDeliveryMethodIndex,
    selectedPrintAndShipIndex,
  } = item
  const variant = item.product.variants[selectedVariantIndex]
  // const downloadLoading = true

  useEffect(() => {
    async function getDownload() {
      if (
        selectedDeliveryMethodIndex === 2 &&
        variant.inDesignFile &&
        variant.inDesignFile !== ''
      ) {
        setHasInDesign(true)
        setDownloadLoading(true)
        http
          .post({
            url: '/indesign/download-package',
            data: {
              uri: variant.inDesignFile,
              channel: channel.selectedChannel || ' ',
              isPreferred: isNonEmptyArray(
                channel.associatedMasterDealerIds[selectedMasterDealerIndex]
                  .dealerTypes
              )
                ? channel.associatedMasterDealerIds[
                    selectedMasterDealerIndex
                  ].dealerTypes!.includes(DealerType.PREFERRED_DEALER)
                  ? 'true'
                  : 'false'
                : 'false',
              dealerName: item.dealerName || '',
              dealerPhone: item.dealerPhone || '',
              dealerAddress: item.dealerAddress || '',
              dealerAddress2: item.dealerAddress2 || '',
              dealerImage: item.dealerImage || '',
            },
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((result: any) => {
            setDownload(result.data['package.zip'])
            setDownloadLoading(false)
          })
      }

      console.log('getDownload')
      // if (downloadLoading) {

      // }
    }
    getDownload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pieceCount =
    (variant.printAndShipOptions[selectedDeliveryMethodIndex] &&
      variant.printAndShipOptions[selectedDeliveryMethodIndex].pieceCount) ??
    0

  return (
    <S.ProductItem>
      <S.ThumbnailContainer>
        <S.Thumbnail src={variant.media[0]} alt={item.product.title} />
      </S.ThumbnailContainer>
      <S.ProductDetail>
        <S.ProductRow>
          <S.ProductTitle>{item.product.title}</S.ProductTitle>
          <S.Text alignSelf="flex-start">
            $
            {
              // TODO: Make work with non-zero-dollar items
              selectedDeliveryMethodIndex === 1
                ? variant.printAndShipOptions[selectedPrintAndShipIndex].price
                : 0
            }
          </S.Text>
        </S.ProductRow>
        <S.Text color={'#9fa8b3'}>
          {ProductHelper.getDeliveryMethod(
            item.product.deliveryMethods[selectedDeliveryMethodIndex - 1],
            pieceCount
          )}
        </S.Text>
        <S.Text color={'#9fa8b3'}>
          {ProductHelper.getVariantTitle(variant)}
        </S.Text>
        {selectedDeliveryMethodIndex === 2 ? (
          hasInDesign ? (
            downloadLoading ? (
              <Spinner spinnerSize={4} />
            ) : (
              <S.DownloadingButton
                href={`data:application/octet-stream;base64,${download}`}
                // href={`data:application/octet-stream;base64,${selectedChannel}`}
                download={`${item.product.title}.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Download files
              </S.DownloadingButton>
            )
          ) : channel.selectedChannel !== 'NR' ? (
            <S.Text color={'#9fa8b3'}>
              No Dealer Box Available for this item
            </S.Text>
          ) : null
        ) : null}
        {selectedDeliveryMethodIndex === 2 && variant.downloadFile && (
          <S.DownloadingButton
            href={variant.downloadFile}
            download
            target="_blank"
            rel="noreferrer"
          >
            Download files
          </S.DownloadingButton>
        )}
      </S.ProductDetail>
    </S.ProductItem>
  )
}
