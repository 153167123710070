/* eslint-disable @typescript-eslint/no-explicit-any */
import * as S from './styles'

import {
  Dealer,
  DealerResponse,
  UserProfile,
  IDealerForm,
  LogData,
  UserActivityLog,
  LogType,
  LogItemType,
  AssociatedMasterDealer,
} from '@monorepo/interfaces'
import { Pagination, SectionTeamMembersList } from '../..'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'

import {
  PageSpinner,
  Spinner,
  SearchTerm,
  ITeamMembersListFilter,
  TeamMembersListFilter,
} from '@monorepo/components'
import { RegistrationUserResponse } from '../Registration'
import {
  httpUserInfo,
  useDebounce,
  useQueryParams,
  KeycloakHelper,
  ChannelHelper,
  DealerHelper,
  isString,
  isNonEmptyArray,
  LogHelper,
  isNonEmptyString,
  isStringEqualCI,
  isArrayEqual,
  UserActivityLogKey,
} from '@monorepo/infra'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { useKeycloak } from '@react-keycloak/web'
import { SelectableDealer } from '../../organisms/SectionTeamMembersList'

type TeamMemebersProps = {
  profile: RegistrationUserResponse | null
  isAdmin: boolean
  dialog: {
    showEditDialog: boolean
    showCreateDialog: boolean
    showDeleteDialog: boolean
    setShowEditDialog: (toggle: boolean) => void
    setShowCreateDialog: (toggle: boolean) => void
    setShowDeleteDialog: (toggle: boolean) => void
  }
}

const TeamMembers: React.FC<TeamMemebersProps> = ({
  isAdmin,
  profile,
  dialog,
}) => {
  const [paginationURL, setPaginationURL] = useState('page=1&pageSize=20')
  const [pagination, setPagination] = useState({
    totalCount: 1,
    page: 1,
    pageSize: 20,
    totalPages: 2,
  })
  const [dealers, setDealers] = useState<Dealer[] | null>()
  const masterDealerId =
    profile?.dealer?.associatedMasterDealers[0]?.masterDealerId
  const masterDealerIdURL = masterDealerId
    ? `masterDealerId=${masterDealerId}`
    : ''
  const [error, setError] = useState('')
  const [principalEmails, setPrincipalEmails] = useState<string[] | undefined>()

  const handlePageChange = useCallback((pageNumber: number) => {
    setPaginationURL(`page=${pageNumber}&pageSize=20`)
  }, [])

  const { keycloak } = useKeycloak()
  const searchURLKey = 's'
  const queryParams = useQueryParams()
  const [search, setSearch] = useState(queryParams.get(searchURLKey) ?? '')
  const debouncedSearch = useDebounce(search, 1000)

  const [loading, setLoading] = useState(false)
  const previousApiUrl = useRef<string | undefined>(undefined)

  const [selectedDealerIds, setSelectedDealerIds] = useState<string[]>([])
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)
  const [isDeleteDealersDealogOpen, setIsDeleteDealersDealogOpen] =
    useState<boolean>(false)

  const [filter, setFilter] = useState<
    ITeamMembersListFilter | null | undefined
  >(undefined)

  const handleUserActivity = async (
    data: IDealerForm,
    userId?: string,
    changes?: UserActivityLog[]
  ) => {
    const previousData = dealers?.find((dealer) => dealer._id === data.userId)
    const currentChanges = DealerHelper.getChanges(
      previousData as unknown as Dealer,
      data,
      userId,
      changes
    )

    if (isNonEmptyArray(currentChanges.changes)) {
      LogHelper.logAsync(
        {
          userActivity: currentChanges,
          type: LogType.USER_ACTIVITY,
          itemType: LogItemType.DEALER_DATA_CHANGING,
        } as LogData,
        KeycloakHelper.getToken(keycloak)
      )
    }
  }

  const handleCreateUser = async (data: IDealerForm) => {
    try {
      const createDealerResponse = await DealerHelper.createDealerAsync(data)
      if (isNonEmptyString(createDealerResponse)) {
        setError(createDealerResponse)
        return
      }

      handleUserActivity(
        data,
        (createDealerResponse.data as UserProfile)._id as string,
        [
          {
            key: UserActivityLogKey.TEAM_MEMBER_ADDED,
            previusValue: 'undefined',
            currentValue: (data.email as string) || 'undefined',
          },
          {
            key: UserActivityLogKey.DEALER_ID,
            previusValue: 'undefined',
            currentValue:
              (
                createDealerResponse.data as UserProfile
              )?.dealer?._id?.toString() || 'undefined',
          },
        ]
      )

      setError('')
      dialog.setShowCreateDialog(false)
      getData(true)
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteUser = async (
    userId: string,
    dealerId: string,
    email?: string
  ) => {
    if (
      !isNonEmptyString(userId) ||
      !isNonEmptyString(dealerId) ||
      !isNonEmptyString(email)
    ) {
      return
    }

    try {
      await httpUserInfo.delete({ url: `/dealer/${dealerId}` })
      await httpUserInfo.delete({ url: `/user/${email}` })

      LogHelper.logAsync(
        {
          userActivity: {
            userId,
            changes: [
              {
                key: UserActivityLogKey.TEAM_MEMBER_DELETED,
                previusValue: 'undefined',
                currentValue: email,
              },
            ],
          },
          type: LogType.USER_ACTIVITY,
          itemType: LogItemType.DEALER_DELETING,
        } as LogData,
        KeycloakHelper.getToken(keycloak)
      )
    } catch (err) {
      console.error(err)
    } finally {
      dialog.setShowDeleteDialog(false)
      getData(true)
    }
  }

  const handleEditUser = async (data: IDealerForm) => {
    try {
      const userData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email?.trim().toLowerCase(),
        groups: data.groups,
        dealerTypes: data.dealerTypes,
      }

      await httpUserInfo.patch({
        url: `/user/${data.userId}`,
        data: userData,
      })

      const dealer = cloneDeep(
        dealers?.find((dealer) => dealer._id === data.userId)?.dealer
      )
      if (dealer) {
        DealerHelper.patchAssociatedMasterDealers(
          dealer.associatedMasterDealers as AssociatedMasterDealer[]
        )

        const associatedMasterDealers = ChannelHelper.getAssociatedMasterDealer(
          dealer.associatedMasterDealers as AssociatedMasterDealer[],
          data.masterDealerId
        )
        const isChannelsDifferent = !isArrayEqual(
          associatedMasterDealers?.channels,
          data.channels,
          isStringEqualCI
        )

        if (isChannelsDifferent || dealer.hasCoop !== data.hasCoop) {
          if (isChannelsDifferent) {
            associatedMasterDealers!.channels = data.channels as string[]
          }

          const dealerData = {
            hasCoop: data.hasCoop,
            associatedMasterDealers,
            userEmail: data.email,
            coopAllowances: data.coopAllowances,
            uniqueDealerId: data.uniqueDealerId?.trim(),
            businessName: data.businessName?.trim(),
          }

          await httpUserInfo.patch({
            url: `/dealer/${data.dealerId}`,
            data: dealerData,
          })
        }

        data.associatedMasterDealers =
          dealer.associatedMasterDealers as AssociatedMasterDealer[]
      }

      handleUserActivity(data)
      dialog.setShowEditDialog(false)
      getData(true)
    } catch (err) {
      console.error(err)
    }
  }

  const handleClearSearch = () => {
    setSearch('')
  }

  const getData = (isReload?: boolean) => {
    const searchURL =
      debouncedSearch.length > 2
        ? `search=${encodeURIComponent(debouncedSearch)}`
        : ''
    if (masterDealerIdURL) {
      const apiURL = `/dealer?${paginationURL}&withCoopAllowances=true&${masterDealerIdURL}${
        !!searchURL ? `&${searchURL}` : ''
      }${!!filter?.name ? `&name=${encodeURIComponent(filter?.name)}` : ''}${
        !!filter?.email ? `&email=${encodeURIComponent(filter?.email)}` : ''
      }${
        Array.isArray(filter?.channels) && filter!.channels.length
          ? filter!.channels
              .map((item) => `&channels[]=${encodeURIComponent(item)}`)
              .join('')
          : ''
      }${
        !!filter?.groups ? `&groups=${encodeURIComponent(filter?.groups)}` : ''
      }${
        !!filter?.coopPermission
          ? `&coopPermission=${encodeURIComponent(filter?.coopPermission)}`
          : ''
      }${
        !!filter?.coopBalance
          ? `&coopBalance=${encodeURIComponent(filter?.coopBalance)}`
          : ''
      }`

      if (!isReload && previousApiUrl.current === apiURL) {
        return
      }

      previousApiUrl.current = apiURL
      setLoading(true)
      httpUserInfo
        .get<DealerResponse>({
          url: apiURL,
        })
        .then((response) => {
          const { totalCount, page, pageSize, totalPages, data } = response.data

          setPagination({
            totalCount,
            page,
            pageSize,
            totalPages,
          })
          setDealers(getSelectedDealers(data, selectedDealerIds))
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    getData()
  }, [masterDealerIdURL, paginationURL, debouncedSearch, filter])

  useEffect(() => {
    getDealerUniqueEmails()
  }, [])

  const getDealerUniqueEmails = () => {
    axios
      .get('/United/DealerUniqueEmails', {
        baseURL: process.env.REACT_APP_TX_BASE_URL,
      })
      .then((response) => {
        setPrincipalEmails(response?.data?.DealerEmails)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSelectedDealers = (
    dealers: Dealer[],
    selectedDealerIds: string[]
  ): SelectableDealer[] => {
    return dealers.map((item) => ({
      ...item,
      selected: selectedDealerIds.indexOf(item._id as string) !== -1,
    }))
  }

  const selectAll = (selected: boolean) => {
    if (!selected) {
      return
    }

    const tempSelectedDealerIds = dealers?.reduce((acc: string[], item) => {
      acc.push(item._id as string)
      return acc
    }, [])
    setSelectedDealerIds(tempSelectedDealerIds as string[])

    setDealers(
      getSelectedDealers(dealers as Dealer[], tempSelectedDealerIds as string[])
    )

    setIsSelectAll(true)
  }

  const selectOne = (id: string, selected: boolean) => {
    const tempSelectedDealerIds = [...selectedDealerIds]
    const idx = tempSelectedDealerIds.indexOf(id)
    if (selected) {
      if (idx === -1) {
        tempSelectedDealerIds.push(id)
      }
    } else {
      if (idx !== -1) {
        tempSelectedDealerIds.splice(idx, 1)
      }
    }
    setSelectedDealerIds(tempSelectedDealerIds)

    const tempSelectedDealers = getSelectedDealers(
      dealers as Dealer[],
      tempSelectedDealerIds
    )
    setDealers(tempSelectedDealers)

    setIsSelectAll(tempSelectedDealers.every((item) => item.selected))
  }

  const handleRemoveSelectedClick = () => {
    if (!selectedDealerIds.length || !isAdmin) {
      return
    }

    setIsDeleteDealersDealogOpen(true)
  }

  const closeDeleteDealersDialog = () => {
    setIsDeleteDealersDealogOpen(false)
  }

  const handleDeleteUsersClickAsync = async () => {
    if (!isNonEmptyArray(dealers as Dealer[]) || !isAdmin) {
      return
    }

    const dealersToDelete = (dealers as SelectableDealer[]).filter(
      (item) =>
        item.selected &&
        isNonEmptyString(item.email) &&
        !DealerHelper.isPrincipal(item, principalEmails)
    )

    try {
      await deleteDealersAsync(dealersToDelete)
    } catch (error) {
      console.log(error)
    } finally {
      setIsDeleteDealersDealogOpen(false)
      getData(true)
    }
  }

  const deleteDealersAsync = async (dealers?: Dealer[]) => {
    if (!Array.isArray(dealers) || !dealers.length) {
      return
    }

    try {
      const requests: Promise<any>[] = []

      dealers.forEach((item) => {
        requests.push(
          httpUserInfo.delete({ url: `/dealer/${item.dealer?._id}` })
        )
        requests.push(httpUserInfo.delete({ url: `/user/${item._id}` }))
      })

      await Promise.all(requests)
    } catch (error) {
      console.log(error)
    }
  }

  if (!dealers) return <PageSpinner />

  return (
    <S.Container>
      <S.ToolbarContainer>
        <S.SelectAllContainer hidden={!isAdmin}>
          <input
            type="checkbox"
            checked={isSelectAll}
            onChange={(e) => selectAll(e.target.checked)}
          />
          <S.SelectAllLabel>Select all</S.SelectAllLabel>
          <S.SelectAllButton onClick={handleRemoveSelectedClick}>
            Remove
          </S.SelectAllButton>
        </S.SelectAllContainer>
        <S.SearchContainer>
          <S.Search
            placeholder={'Search Team Members'}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <SearchTerm searchTerm={search} onClear={handleClearSearch} />
        </S.SearchContainer>
      </S.ToolbarContainer>
      <TeamMembersListFilter onChange={setFilter} />
      {!!loading ? (
        <Spinner spinnerSize={8} />
      ) : (
        <>
          <SectionTeamMembersList
            isAdmin={isAdmin}
            dealers={dealers}
            dialog={dialog}
            loading={!dealers}
            createDealer={handleCreateUser}
            deleteDealer={handleDeleteUser}
            editDealer={handleEditUser}
            error={error}
            principalEmails={principalEmails}
            selectable={isAdmin}
            selectOne={selectOne}
          />
          <Pagination
            onPageChange={handlePageChange}
            totalCount={pagination.totalCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalPages={pagination.totalPages}
          />
        </>
      )}
      <Dialog
        aria-label="DeleteDealersDialog"
        open={isDeleteDealersDealogOpen}
        onClose={closeDeleteDealersDialog}
      >
        <DialogTitle aria-label="DeleteDealersDialogTitle">
          {'Are you sure you want to delete the users?'}
        </DialogTitle>
        <DialogContent aria-label="DeleteDealersDialogContent">
          <DialogContentText>
            {(dealers as SelectableDealer[])
              .filter(
                (item) =>
                  isString(item.email) && !!item.email?.trim() && item.selected
              )
              .map((item) => item.email?.trim()?.toUpperCase())
              .join(', ')}
          </DialogContentText>
          <DialogActions>
            <Button onClick={closeDeleteDealersDialog}>CANCEL</Button>
            <Button onClick={handleDeleteUsersClickAsync}>Delete users</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </S.Container>
  )
}

export default TeamMembers
